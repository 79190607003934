import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageChange = () => {
  const { i18n } = useTranslation();
  const [isDropdownOpen, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdown(!isDropdownOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <img
        src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/langChange.svg"
        alt="Change Language"
        className="w-8 h-8 cursor-pointer"
        onClick={toggleDropdown}
      />
      {isDropdownOpen && (
        <div className="absolute z-10 flex flex-col justify-center items-center mt-1 w-32 right-0 origin-top-right rounded-[1.25rem] bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
          <button
            className="block px-4 py-2 text-md font-medium text-gray-400 font-sora hover:text-[#222157] border-b-[#E2EBFF] border-b-[1.5px] text-center"
            onClick={() => changeLanguage("en")}
          >
            English
          </button>
          <button
            className="block px-4 py-2 text-md font-medium text-gray-400 font-sora hover:text-[#222157] text-center"
            onClick={() => changeLanguage("ar")}
          >
            عربي
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageChange;
