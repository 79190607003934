import React from "react";

const Footer = () => {
  return (
    <div>
      <section
        className="mx-[2.4rem]"
        style={{ fontFamily: "sora !important" }}
      >
        <div className="flex justify-between flex-wrap items-center border-t-[#E2EBFF] border-t-[2px] py-2 px-[1.5rem]">
          <div className="flex justify-between items-center gap-[3rem] text-[#222157]">
            <img
              src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/footer/footer.png"
              alt="fakkah"
              className="  w-[180px] mx-2"
            />
          </div>
          <div>
            <p className="text-sm py-2 text-[#828282]">
              Email: support@fakkah.com
            </p>
            <p className="text-sm py-2 text-[#828282]">Phone: +966-XXXXXXXXX</p>
            <p className="text-sm py-2 text-[#828282] text-wrap">
              Address: Riyadh, KSA
            </p>
          </div>
          <div>
            <p className="text-lg py-1 text-[#828282]">Contact Us</p>
            <p className="text-lg py-1 text-[#828282]">Terms and Conditions</p>
            <p className="text-[0.8rem] py-1 text-[#828282]">
              ©2024 Fakkah by NamlSoft. All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
