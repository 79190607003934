// import React, { useEffect, useRef, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { FiMenu } from "react-icons/fi";
// import { IoCloseOutline } from "react-icons/io5";

// const Navbar = ({ scrollToSection }) => {
//   const { t, i18n } = useTranslation();
//   const [isInitialized, setIsInitialized] = useState(false);
//   const [isSideMenuOpen, setMenu] = useState(false);
//   const [isDropdownOpen, setDropdown] = useState(false);
//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     if (i18n.isInitialized) {
//       setIsInitialized(true);
//     }

//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdown(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [i18n.isInitialized]);

//   useEffect(() => {
//     document.body.style.fontFamily = "'Sora', sans-serif";
//   }, [i18n.language]);

//   const navlinks = [
//     { label: "navbar.aboutFakkah", link: "about" },
//     { label: "navbar.features", link: "features" },
//     { label: "navbar.useCases", link: "use" },
//   ];

//   const toggleDropdown = () => {
//     setDropdown(!isDropdownOpen);
//   };

//   const changeLanguage = (lang) => {
//     i18n.changeLanguage(lang);
//     setDropdown(false);
//   };

//   if (!isInitialized) return <div>Loading...</div>;

//   return (
//     <main className="px-[2rem] font-sora">
//       <nav className="flex justify-between items-center py-4 md:py-6 border-b-[1.5px] border-b-[#E2EBFF] bg-white relative z-50">
//         <div className="flex items-center gap-8">
//           <section className="flex items-center gap-4">
//             <img
//               src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/nav-fakkah-logo.svg"
//               alt="Fakkah Logo"
//               className="w-[7rem] mr-2"
//             />
//           </section>
//           {navlinks.map((d, i) => (
//             <p
//               key={i}
//               className="hidden lg:block sora-font text-gray-400 font-sora hover:text-black font-bold cursor-pointer"
//               onClick={() => scrollToSection(d.link)}
//             >
//               {t(d.label)}
//             </p>
//           ))}
//         </div>

//         <div
//           className={`fixed h-full w-screen lg:hidden backdrop-blur-sm top-0 right-0 ${
//             isSideMenuOpen ? "translate-x-0" : "-translate-x-full"
//           } transition-all`}
//         >
//           <section className="text-black p-[1rem] w-[100%] h-[100vh] ml-[-1rem] pl-[1.5rem] bg-white flex-col absolute left-0 top-0 gap-6 md:gap-8 z-100 flex">
//             <div className="flex justify-between items-center border-b-[2px] border-b-[#E2EBFF] py-2 md:py-6">
//               <img
//                 src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/nav-fakkah-logo.svg"
//                 alt="Fakkah Logo"
//                 className="h-10"
//               />
//               <img
//                 src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/langChange.svg"
//                 alt="Change Language"
//                 className="w-8 h-8 cursor-pointer"
//                 onClick={toggleDropdown}
//               />
//               <IoCloseOutline
//                 onClick={() => setMenu(false)}
//                 className="mt-0 text-3xl cursor-pointer"
//               />
//             </div>
//             <div className="flex flex-col">
//               {navlinks.map((d, i) => (
//                 <p
//                   key={i}
//                   className="font-semibold cursor-pointer py-4 border-b-[#E2EBFF] border-b-[1.2px]"
//                   onClick={() => {
//                     scrollToSection(d.link);
//                     setMenu(false);
//                   }}
//                 >
//                   {t(d.label)}
//                 </p>
//               ))}
//             </div>
//           </section>
//         </div>

//         <section className="flex items-center gap-4">
//           <div
//             className={`relative ${isSideMenuOpen ? "hidden" : ""}`}
//             ref={dropdownRef}
//           >
//             <img
//               src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/langChange.svg"
//               alt="Change Language"
//               className="w-8 h-8 cursor-pointer"
//               onClick={toggleDropdown}
//             />
//             {isDropdownOpen && (
//               <div className="absolute z-10 flex flex-col justify-center items-center mt-1 w-32 right-0 origin-top-right rounded-[1.25rem] bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
//                 <button
//                   className="block px-4 py-2 text-md font-medium text-gray-400 font-sora hover:text-[#222157] border-b-[#E2EBFF] border-b-[1.5px] text-center"
//                   onClick={() => changeLanguage("en")}
//                 >
//                   English
//                 </button>
//                 <button
//                   className="block px-4 py-2 text-md font-medium text-gray-400 font-sora hover:text-[#222157] text-center"
//                   onClick={() => changeLanguage("ar")}
//                 >
//                   عربي
//                 </button>
//               </div>
//             )}
//           </div>
//           <FiMenu
//             onClick={() => setMenu(true)}
//             className="text-3xl cursor-pointer lg:hidden"
//           />
//         </section>
//       </nav>
//       <hr />
//     </main>
//   );
// };

// export default Navbar;
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiMenu } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import LanguageChange from "../../utils/LanguageChange/LanguageChange";

const Navbar = ({ scrollToSection }) => {
  const { t, i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSideMenuOpen, setMenu] = useState(false);

  useEffect(() => {
    if (i18n.isInitialized) {
      setIsInitialized(true);
    }
  }, [i18n.isInitialized]);

  useEffect(() => {
    document.body.style.fontFamily = "'Sora', sans-serif";
  }, [i18n.language]);

  const navlinks = [
    { label: "navbar.aboutFakkah", link: "about" },
    { label: "navbar.features", link: "features" },
    { label: "navbar.useCases", link: "use" },
  ];

  if (!isInitialized) return <div>Loading...</div>;

  return (
    <main className="px-[1rem] sm:px-[2rem] font-sora">
      <nav className="flex justify-between items-center py-4 md:py-6 border-b-[1.5px] border-b-[#E2EBFF] bg-white relative z-50">
        <div className="flex items-center gap-8">
          <section className="flex items-center gap-4">
            <img
              src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/nav-fakkah-logo.svg"
              alt="Fakkah Logo"
              className="w-[6rem] sm:w-[7rem] mr-2"
            />
          </section>
          {navlinks.map((d, i) => (
            <p
              key={i}
              className="hidden lg:block sora-font text-gray-400 font-sora hover:text-black font-bold cursor-pointer"
              onClick={() => scrollToSection(d.link)}
            >
              {t(d.label)}
            </p>
          ))}
        </div>

        {/* Sidebar Menu */}
        <div
          className={`fixed h-full w-screen lg:hidden backdrop-blur-sm top-0 right-0 px-[2rem] ${
            isSideMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-all`}
        >
          <section className="text-black px-[1rem] w-[100%] h-[100vh] bg-white flex-col absolute left-0 top-0 gap-6 md:gap-8 z-100 flex">
            <div className="flex justify-between items-center border-b-[2px] border-b-[#E2EBFF] py-4 md:py-6">
              <img
                src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/navbar/nav-fakkah-logo.svg"
                alt="Fakkah Logo"
                className="w-[6rem] sm:w-[7rem] mr-2"
              />
              <div className="flex items-center gap-3">
                <LanguageChange />
                <IoCloseOutline
                  onClick={() => setMenu(false)}
                  className="mt-0 text-3xl cursor-pointer"
                />
              </div>
            </div>
            <div className="flex flex-col">
              {navlinks.map((d, i) => (
                <p
                  key={i}
                  className="font-semibold cursor-pointer py-4 border-b-[#E2EBFF] border-b-[1.2px]"
                  onClick={() => {
                    scrollToSection(d.link);
                    setMenu(false); // Close sidebar menu on link click
                  }}
                >
                  {t(d.label)}
                </p>
              ))}
            </div>
          </section>
        </div>

        {/* Language Change and Hamburger Menu */}
        <section className="flex items-center gap-4">
          <div className={`${isSideMenuOpen ? "hidden" : ""}`}>
            <LanguageChange />
          </div>
          <FiMenu
            onClick={() => setMenu(true)}
            className="text-3xl cursor-pointer lg:hidden"
          />
        </section>
      </nav>
      <hr />
    </main>
  );
};

export default Navbar;
