// import i18n from "i18next";
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init(
//     {
//       debug: true,
//       fallbackLng: "en",
//       interpolation: {
//         escapeValue: false, // React already does escaping
//       },
//       backend: {
//         loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
//       },
//       react: {
//         useSuspense: false,
//       },
//     },
//     (err, t)  => {
//       if (err) console.error("i18next initialization error:", err);
//       console.log("i18next initialized successfully");
//     }
//   );

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
