import React, { useRef, useEffect } from "react";
import "./App.css";
import "./index.css";
import { AboutSection } from "./Components/AboutSection";
import Footer from "./Components/Footer";
import GetApp from "./Components/GetApp";
import HeroSection from "./Components/HeroSection";
import Navbar from "./Components/Navbar/Navbar";
import UseSection from "./Components/UseSection";
import { useTranslation } from "react-i18next";
function App() {
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const useSectionRef = useRef(null);
  const getAppRef = useRef(null);
  const { i18n } = useTranslation();
  const scrollToSection = (section) => {
    switch (section) {
      case "hero":
        heroRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "use":
        useSectionRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "features":
        getAppRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Update font family on language change
    document.body.style.fontFamily =
      i18n.language === "ar" ? "'Sora', sans-serif" : "'Sora', sans-serif";
  }, [i18n.language]);
  return (
    <div className="font-sora">
      <Navbar scrollToSection={scrollToSection} />
      <div ref={heroRef}>
        <HeroSection />
      </div>
      <div ref={aboutRef}>
        <AboutSection />
      </div>
      <div ref={useSectionRef}>
        <UseSection />
      </div>
      <div ref={getAppRef}>
        <GetApp />
      </div>
      <Footer />
    </div>
  );
}

export default App;
