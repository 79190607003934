import React from "react";
import { useTranslation } from "react-i18next";
import hand from "../assets/use-sec-img/hand.svg";

const UseSection = () => {
  const { t, i18n } = useTranslation();

  // Define inline styles for text alignment and direction based on language
  const dirComp = {
    textAlign: i18n.language === "ar" ? "right" : "left",
    // direction: i18n.language === "ar" ? "rtl" : "ltr",
  };

  return (
    <div className="flex flex-col-reverse font-sora sm:flex-row mt-[3rem] justify-between gap-[0.8rem] items-center">
      <div>
        <img
          src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/assets/assets/use-sec-img/hand.svg"
          alt="hand-img"
        />
      </div>
      <div className="mx-[1rem] md:mr-[2rem] pl-[0.5rem] " style={dirComp}>
        <h2 className="text-[#222157] font-bold text-2xl md:text-3xl">
          {t("UseSection.title")}
        </h2>
        <div className="py-4">
          <h3 className="text-[#222157] text-[16px] md:text-[18px] font-semibold">
            {t("UseSection.retailTitle")}
          </h3>
          <p className="text-[#4B4B4B] text-[14px] md:text-[16px] font-medium">
            {t("UseSection.retailDesc")}
          </p>
        </div>
        <div className="py-4">
          <h3 className="text-[#222157] text-[16px] md:text-[18px] font-semibold">
            {t("UseSection.cafesTitle")}
          </h3>
          <p className="text-[#4B4B4B] text-[14px] md:text-[16px] font-medium">
            {t("UseSection.cafesDesc")}
          </p>
        </div>
        <div className="py-4">
          <h3 className="text-[#222157] text-[16px] md:text-[18px] font-semibold">
            {t("UseSection.parksTitle")}
          </h3>
          <p className="text-[#4B4B4B] text-[14px] md:text-[16px] font-medium">
            {t("UseSection.parksDesc")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UseSection;
