// import React from "react";
// import { useTranslation } from "react-i18next";
// import heroImage from "../assets/hero-sec-img/hero.svg";

// const HeroSection = () => {
//   const { t, i18n } = useTranslation();

//   // Determine text direction based on current language
//   const textDirectionClass =
//     i18n.language === "ar" ? "text-right" : "text-left";

//   const dirComp = {
//     textAlign: i18n.language === "ar" ? "right" : "left",
//     direction: i18n.language === "ar" ? "rtl" : "ltr",
//   };
//   return (
//     <div
//       className="min-h-screen font-sora bg-white flex flex-col mx-[0.5rem] lg:flex-row items-center justify-center"
//       style={dirComp}
//     >
//       <div className={` lg:w-full px-8 ${textDirectionClass}  `}>
//         <h1
//           className={`text-2xl mt-[1rem] md:mt-1 font-sora sm:text-4xl md:text-6xl font-extrabold mb-4 text-wrap text-[#222157]`}
//           dangerouslySetInnerHTML={{ __html: t("HeroSection.heroTitle") }}
//         />
//         <p
//           className={`${textDirectionClass} text-[14px] font-sora sm:text-[16px] font-semibold text-[#222157] mb-8`}
//         >
//           {t("HeroSection.heroDescription")}
//         </p>
//         <button
//           className={`${textDirectionClass} bg-[#03D798] font-sora text-white py-2 px-4 font-semibold rounded-full text-base md:text-lg hover:bg-[#03D798]`}
//           style={dirComp}
//         >
//           {t("HeroSection.heroBtn")}
//         </button>
//       </div>
//       <div className="w-full flex justify-center items-center relative mt-12 lg:mt-0">
//         <img src={heroImage} alt="Hero" />
//       </div>
//     </div>
//   );
// };

// export default HeroSection;

import React from "react";
import { useTranslation } from "react-i18next";
import heroImage from "../assets/hero-sec-img/hero.svg";
import "../index.css";
import "./comp.css";
const HeroSection = () => {
  const { t, i18n } = useTranslation();

  const textDirectionClass =
    i18n.language === "ar" ? "text-right" : "text-left";

  const dirComp = {
    textAlign: i18n.language === "ar" ? "right" : "left",
    direction: i18n.language === "ar" ? "rtl" : "ltr",
  };

  return (
    <div
      className="min-h-screen mt-[1px] font-sora bg-white flex flex-col mx-[0.5rem] lg:flex-row items-center justify-center"
      style={dirComp}
    >
      <div id="add-font" className={`lg:w-full px-8 ${textDirectionClass}`}>
        <h1
          id="add-font"
          className={`text-2xl mt-[1rem] md:mt-1 sm:text-4xl md:text-6xl font-extrabold mb-4 text-wrap text-[#222157]`}
          dangerouslySetInnerHTML={{ __html: t("HeroSection.heroTitle") }}
        />
        <p
          id="add-font"
          className={`text-[14px] sm:text-[16px] font-semibold text-[#222157] mb-8`}
        >
          {t("HeroSection.heroDescription")}
        </p>
        <button
          className={`bg-[#03D798] text-white py-2 px-6 font-semibold rounded-full text-base md:text-lg hover:bg-[#03D798]`}
        >
          {t("HeroSection.heroBtn")}
        </button>
      </div>
      <div className="w-full flex justify-center items-center relative mt-12 lg:mt-0">
        <img
          src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/assets/assets/hero-sec-img/hero.svg"
          alt="Hero"
        />
      </div>
    </div>
  );
};

export default HeroSection;
