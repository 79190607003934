import React from "react";
import { useTranslation } from "react-i18next";

const GetApp = () => {
  const { t, i18n } = useTranslation();

  const text = [
    t("GetApp.manageNFC"),
    t("GetApp.trackTransactions"),
    t("GetApp.latestUpdates"),
    t("GetApp.goCashFree"),
  ];

  // Define inline styles for text alignment and direction based on language
  const dirComp = {
    textAlign: i18n.language === "ar" ? "right" : "left",
    direction: i18n.language === "ar" ? "rtl" : "ltr",
  };

  return (
    <div className="flex justify-center my-[6rem] font-sora mx-[1rem]">
      <div className="w-full sm:w-[55rem] justify-center h-[19rem] md:justify-between rounded-lg px-6 relative bg-slate-100 flex">
        <div className="mx-[3rem] py-[1rem]" style={dirComp}>
          <h1 className="text-[#51267F] text-nowrap font-sora font-extrabold text-[18px] w-full pt-[1rem]">
            {t("GetApp.downloadTitle")}
          </h1>
          {text.map((item, index) => (
            <div key={index} className="flex gap-2 my-4">
              <img
                src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/use/tick.svg"
                alt="right tick"
              />
              <p className="text-[#222157] font-semibold">{item}</p>
            </div>
          ))}
          <div className="flex gap-2 py-1 mx-2">
            <button>
              <img
                src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/use/plystore.svg"
                alt="appstore"
                className="cursor-pointer w-[120] h-[50px]"
              />
            </button>
            <button>
              <img
                src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/use/aplstore.svg"
                alt="aplstore"
                className="cursor-pointer w-[120] h-[50px]"
              />
            </button>
          </div>
        </div>
        <div className="justify-center items-center hidden md:flex">
          <img
            src="https://fakkah-stage.s3.ap-south-1.amazonaws.com/fakkah-svg/use/fband.svg"
            alt="band"
            className="h-[55rem] ml-[3rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default GetApp;
